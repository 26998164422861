import "./project-modal.scss"
import {FC} from "react"
import Project from "../../../model/project"
import { Modal } from "antd"

interface ProjectModalProps {
    project: Project
    open: boolean
    onClose: () => void
}

export const ProjectModal: FC<ProjectModalProps> = ({project, open, onClose}) => {
    const styles={
        backgroundImage: `url(${project.image})`,
    }

    const formatDescription = () => {
        if (!open) {
            return;
        }
        const description = project.descriptionLong ? project.descriptionLong : project.description

        // splits the description on \n, then adds <p> tags around each line
        const lines = [] as any[]
        description.split("\n").forEach((line, index) => {
            if (index !== 0 && line.length === 0) {
                lines.push(<br key={`${index}`}/>)
            } else if (line.length > 0) {
                lines.push(<p key={index}>{`${line}`}</p>)   
            }
        })

        return lines
    }

    return (
        <Modal 
            destroyOnClose 
            open={open} 
            onCancel={onClose} 
            footer={null} 
            rootClassName="project-modal-root" 
            className="project-modal"
            centered
            mask 
            maskProps={{className: `project-modal-mask ant-modal-mask ${open ? "opened" : "closed"}`}}>
                <div className="project-modal-content" style={styles}>
                    <div className="project-modal-text">
                        <div className="project-modal-title-wrapper dim-overlay">
                            <h2 className="project-modal-title">{project.title}</h2>
                            {
                                project.tags && project.tags.length > 0 &&
                                <p className="project-modal-tags">[ {project.tags.join(", ")} ]</p>
                            }
                        </div>
                        <div className="project-modal-description dim-overlay">
                            {formatDescription()}
                        </div>
                        {
                            project.link &&
                            <p className="project-modal-link dim-overlay">Project Link: <a href={project.link} target="_blank" rel="noreferrer">{project.link}</a></p>
                        }
                    </div>
                </div>
        </Modal>
    )
}

export default ProjectModal