import './projects-page.scss'
import { FC, useEffect, useState } from "react"

import allProjects from '../../data/projects.json'
import Project from '../../model/project'
import { Select, Switch } from 'antd'
import { useSearchParams } from 'react-router-dom'
import ProjectTile from './project-tile/project-tile'
import ProjectModal from './project-modal/project-modal'

const ProjectsPage: FC = () => {
    const [projects, setProjects] = useState(allProjects as Project[])
    const [tags, setTags] = useState([] as string[])
    const [selectedTags, setSelectedTags] = useState([] as string[])
    const [params, setParams] = useSearchParams()
    const [filterAnd, setFilterAnd] = useState(true)
    const [showDialog, setShowDialog] = useState(false)
    const [selectedProject, setSelectedProject] = useState<Project>({} as Project)

    useEffect(() => {
        const tags = Array.from(new Set(projects.map(project => project.tags).flat()))
        tags.sort()

        setTags(tags)
        setSelectedTags(params.get('tags') ? params.get('tags')!.split(',') : [])
        setFilterAnd(params.get('andOr') ? params.get('andOr') === "AND" : true)
    }, [params, projects])

    useEffect(() => {
        if (!selectedTags) {
            setProjects(allProjects)
            return;
        }
    }, [selectedTags])

    const onTagChange = (value: string[]) => {
        setSelectedTags(value)
        updateSearchParams(value, filterAnd)
    }

    const onTagSwitchChanged = (value: boolean) => {
        setFilterAnd(value)
        updateSearchParams(selectedTags, value)
    }

    const onProjectClick = (project: Project) => {
        setSelectedProject(project)
        setShowDialog(true)
    }

    const updateSearchParams = (tags: string[], filterAnd: boolean) => {
        const params = new URLSearchParams()
        if (tags.length > 0) {
            params.set('tags', tags.join(','))
        }
        if (!filterAnd) {
            params.set('andOr', "OR")
        }
        setParams(params)
    }


    const hasTags = (project: Project) => {
        if (selectedTags.length === 0) {
            return true;
        }
        if (filterAnd) {
            return selectedTags.every(tag => project.tags.includes(tag))
        }
        return selectedTags.some(tag => project.tags.includes(tag))
    }

    return (
        <>
            <div className="projects-page page-container">
                <div className="projects-header">
                    <h2 className="title">Projects</h2>
                    <div className="filter-wrapper">
                        <p className="filter-label">Filter by tags: </p>
                        <Select 
                            popupClassName='tag-dropdown'
                            className="tag-select"
                            mode='tags'
                            showSearch={false}
                            value={selectedTags}
                            onChange={onTagChange}
                            options={tags.map((tag) => {return {value: tag, label: tag}})}
                            maxTagCount='responsive'
                            maxTagPlaceholder={(omittedValues) => (<span>+{omittedValues.length} more</span>)}/>
                        <Switch className="filter-switch" checkedChildren="AND" unCheckedChildren="OR" defaultChecked onChange={onTagSwitchChanged} value={filterAnd}/>
                    </div>
                </div>
                
                <div className="project-wrapper">
                    {
                        projects.map(project => <ProjectTile project={project} isFiltered={!hasTags(project)} key={project.title} onClick={onProjectClick}/>)
                    }
                </div>
            </div>

            <ProjectModal project={selectedProject} open={showDialog} onClose={() => setShowDialog(false)}/>
        </>
    )
}

export default ProjectsPage