import { Tooltip } from 'antd'
import { LINKS, TEXT } from '../../utils/constants'
import './footer.scss'
import { FC } from "react"
import { GithubOutlined, LinkedinOutlined, MailOutlined } from '@ant-design/icons'

interface FooterProps {

}

const Footer: FC<FooterProps> = () => {
    const startYear = 2024
    const currentYear = new Date().getFullYear()

    const copyrightYears = startYear === currentYear ? `${startYear}` : `${startYear} - ${currentYear}`

    return (
        <div className="footer">
            <div className='footer-wrapper'>
                <div className="footer-links">
                    <Tooltip title={"Github"} destroyTooltipOnHide>
                        <a href={LINKS.GITHUB} target="_blank" rel="noreferrer">
                            <GithubOutlined className="link-icon"/>
                        </a>
                    </Tooltip>
                    
                    <Tooltip title={"Linkedin"} destroyTooltipOnHide>
                        <a href={LINKS.LINKEDIN} target="_blank" rel="noreferrer">
                            <LinkedinOutlined className="link-icon"/>
                        </a>
                    </Tooltip>
                    
                    <Tooltip title={"Email"} destroyTooltipOnHide>
                        <a href={`mailto:${LINKS.EMAIL}`} target="_blank" rel="noreferrer">
                            <MailOutlined className="link-icon"/>
                        </a>
                    </Tooltip>
                </div>
                <p>© {copyrightYears} {TEXT.NAME}</p>
            </div>
        </div>
    )
}

export default Footer