import { FC } from "react"
import "./test-page.scss"

import { Select, Switch } from 'antd'

const TestPage: FC = () => {
    

    return (
        <>
            <div className="test-page page-container">
                <div className="game-wrapper">
                    <iframe src="https://takenouchigamestorage.blob.core.windows.net/games/test/game.html" title="game"/>
                </div>
                <div className="sidebar">
                    Non ullamco velit mollit culpa proident exercitation commodo cillum. Est incididunt elit dolor elit Lorem occaecat aliquip sint esse exercitation ullamco ut ullamco. Commodo dolore proident excepteur occaecat cupidatat ut. Elit id culpa aliquip est veniam nostrud nostrud veniam id quis sint id. Occaecat amet quis amet officia laborum laborum eiusmod non esse ex sint excepteur. Consequat sunt sint aliquip irure aute velit consectetur tempor qui mollit dolor excepteur laborum. Exercitation duis ut aliquip consectetur.
                </div>
            </div>
        </>
    )
}

export default TestPage