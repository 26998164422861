import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes/routes';

function App() {

  const router = createBrowserRouter(routes)

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
