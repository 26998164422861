import './wrapper-styles.scss'
import { RouteObject } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { ReactNode } from "react";
import Header from "../conponents/header/header";
import Footer from "../conponents/footer/footer";
import ProjectsPage from '../layouts/projects-page/projects-page';
import TestPage from '../layouts/test-page/test-page';

const createPage = (page: ReactNode) => {
    return (
        <>
            <Header />
                <div className="page-wrapper">
                    {page}
                </div>
            <Footer />
        </>
    )
}



const routes: RouteObject[] = [
    { 
        index: true,
        path: ROUTES.HOME, 
        element: createPage(<ProjectsPage />),
    },
    {
        path: ROUTES.TEST,
        element: createPage(<TestPage />),
    }
]

export default routes