import { Link } from 'react-router-dom';
import './header.scss'
import { FC } from "react";
import { ROUTES } from '../../utils/constants';

interface HeaderProps {

}

const Header: FC<HeaderProps> = () => {

    return (
        <div className="header">
            <div className="header-wrapper">
                <Link to="/" className="return-home">
                    <img src='/images/icon.png' alt="logo" className="logo"/>
                    <h1 className="name">Shawn Carter</h1>
                </Link>
            </div>
        </div>
    )
}

export default Header