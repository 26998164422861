import { FC, useState } from "react"
import Project from "../../../model/project"
import cn from "classnames";

interface ProjectTileProps {
    project: Project;
    isFiltered?: boolean;
    key?: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: (project: Project) => void;
}

const ProjectTile: FC<ProjectTileProps> = ({project, isFiltered, key, className, style, onClick}) => {
    const [isMousedOver, setIsMousedOver] = useState(false)

    const calculateScale = () => {
        if (!isFiltered) {
            return
        }
        const xScales = [.9, .95, .97]
        const yScales = [.9, .94, .97]
        const xIndex = project.width ? project.width - 1 : 0
        const yIndex = project.height ? project.height - 1 : 0
        return `scaleX(${xScales[xIndex]}) scaleY(${yScales[yIndex]})`
    }

    const getAnimationDirection = () => {
        const width = project.width || 1
        const height = project.height || 1
        if (width >= height) {
            return 'animate-vertical'
        }
        return 'animate-horizontal'
    }

    const classNames = cn(
        'project',
        `col-span-${project.width || 1}`,
        `row-span-${project.height || 1}`,
        {filtered: isFiltered, mousedOver: isMousedOver},
        getAnimationDirection(),
        className
    )

    return (
        <div 
            className={classNames} 
            key={key}
            style={{transform: calculateScale(), backgroundImage: `url(${project.image})`, ...style}}
            onClick={() => onClick && !isFiltered && onClick(project)}
            onMouseEnter={() => setIsMousedOver(true)}
            onMouseLeave={() => setIsMousedOver(false)}>
                <div className="project-title-wrapper">
                    <h3>{project.title}</h3>
                </div>
                <div className="project-description-wrapper">
                    <p className="project-description">{project.description}</p>
                    <p className="project-tags">[ {project.tags.join(', ')} ]</p>
                </div>
        </div>
    )
}

export default ProjectTile