export const TEXT = {
    NAME: 'Shawn Carter',
    JOB_TITLE: 'Software Engineer',
}

export const LINKS = {
    LINKEDIN: 'https://www.linkedin.com/in/shawnjcarter/',
    GITHUB: 'https://github.com/TakenouchiTR',
    EMAIL: 'shawn@shawnjcarter.com',
    GAME_WEBSITE: 'https://takenouchitr.com',
}

export const ROUTES = {
    HOME: '/',
    TIMELINE: '/timeline',
    PROJECTS: '/projects',
    HOW_THIS_WAS_MADE: '/how-this-was-made',
    TEST: '/test',
}

export const STYLES = {
    DEFAULT_PAGE_WIDTH: '75%',
    DEFAULT_MAX_WIDTH: '1200px',
    TEXT_COLOR: 'white',
    DARK_BLUE: '#1C2A35',
    TABLET_BREAK_POINT: '800px',
    MOBILE_BREAK_POINT: '550px',
}